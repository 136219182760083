$gallery: #EFEFEF;
$white: #FFFFFF;
$white-fade: #FFFFFF2E;
$prussian-blue: #003149;
$prussian-blue-fade: #0031490A;
$prussian-blue-2: #003651;
$prussian-blue-2-fade: #0036510A;
$prussian-blue-2-fade-1: #0036513D;
$texas-rose: #FFAC4D;
$texas-rose-fade: #FFAC4D3D;
$silver-chalice: #9E9E9E;
$silver-chalice-fade: #9E9E9ECC;
$gray: #888888;
$gothic: #678E9F;
$mandy: #E2574C;
$tall-poppy: #B53629;
$chateau-green: #45B058;
$sea-green: #349C42;
$firefly-fade: #0B1F2947;
$firefly: #0B1F29;
$black-fade: #00000029;
$black-fade-99: #00000099;
$regal-blue: #004466;
$regal-blue-fade: #0044663D;
$alto: #D0D0D0;
$mine-shaft: #3B3B3B;

$colors: (
    'gallery': ('hex': $gallery, 'str': 'EFEFEF'),
    'white': ('hex': $white, 'str': 'FFFFFF'),
    'white-fade': ('hex': $white-fade, 'str': 'FFFFFF2E'),
    'prussian-blue': ('hex': $prussian-blue, 'str': '003149'),
    'prussian-blue-fade': ('hex': $prussian-blue-fade, 'str': '0031490A'),
    'prussian-blue-2': ('hex': $prussian-blue-2, 'str': '003651'),
    'prussian-blue-2-fade': ('hex': $prussian-blue-2-fade, 'str': '0036510A'),
    'prussian-blue-2-fade-1': ('hex': $prussian-blue-2-fade-1, 'str': '0036513D'),
    'texas-rose': ('hex': $texas-rose, 'str': 'FFAC4D'),
    'texas-rose-fade': ('hex': $texas-rose-fade, 'str': 'FFAC4D3D'),
    'silver-chalice': ('hex': $silver-chalice, 'str': '9E9E9E'),
    'silver-chalice-fade': ('hex': $silver-chalice-fade, 'str': '9E9E9ECC'),
    'gray': ('hex': $gray, 'str': '888888'),
    'gothic': ('hex': $gothic, 'str': '678E9F'),
    'mandy': ('hex': $mandy, 'str': 'E2574C'),
    'tall-poppy': ('hex': $tall-poppy, 'str': 'B53629'),
    'chateau-green': ('hex': $chateau-green, 'str': '45B058'),
    'sea-green': ('hex': $sea-green, 'str': '349C42'),
    'firefly-fade': ('hex': $firefly-fade, 'str': '0B1F2947'),
    'firefly': ('hex': $firefly, 'str': '0B1F29'),
    'black-fade': ('hex': $black-fade, 'str': '00000029'),
    'black-fade-99': ('hex': $black-fade-99, 'str': '00000099'),
    'regal-blue': ('hex': $regal-blue, 'str': '004466'),
    'regal-blue-fade': ('hex': $regal-blue-fade, 'str': '0044663D'),
    'alto': ('hex': $alto, 'str': 'D0D0D0'),
    'mine-shaft': ('hex': $mine-shaft, 'str': '3B3B3B'),
);