.mt-triangle {
    width: 10px;
    height: 10px;
    color: $white;
    position: absolute;
    top: calc(100% + 5px);
    left: 50%;
    transform: translateX(-50%) rotate(-45deg);
    box-shadow: 0px 3px 6px $black-fade;
}

.mt-change-language {
    width: 170px;
    position: absolute;
    top: calc(100% + 10px);
    left: 50%;
    border-radius: 10px;
    transform: translateX(-50%);
    padding: 10px;
    box-shadow: 0px 3px 6px $black-fade;
    background: $white;
}