@for $w from 0 to 1000 {
    .h-#{$w}px {
        height: #{$w}#{"px"};
    }

    .max-h-#{$w}px {
        max-height: #{$w}#{"px"};
    }

    .min-h-#{$w}px {
        min-height: #{$w}#{"px"};
    }
}