.fade-in {
    animation-name: fade-in;
    animation-timing-function: linear;
    animation-duration: 0.2s;
    animation-fill-mode: forwards;
    animation-iteration-count: 1;

    @keyframes fade-in {
        from {
            opacity: 0;
        }

        to {
            opacity: 1;
        }
    }
}