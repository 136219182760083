@for $i from 0 to 1000 {
    .p-#{$i}px {
        padding: #{$i}px !important;
    }

    .pt-#{$i}px {
        padding-top: #{$i}px !important;
    }

    .pb-#{$i}px {
        padding-bottom: #{$i}px !important;
    }

    .ps-#{$i}px {
        padding-left: #{$i}px !important;
    }

    .pe-#{$i}px {
        padding-right: #{$i}px !important;
    }

    .px-#{$i}px {
        padding-left: #{$i}px !important;
        padding-right: #{$i}px !important;
    }

    .py-#{$i}px {
        padding-top: #{$i}px !important;
        padding-bottom: #{$i}px !important;
    }
}

@include media-breakpoint-up(sm) {
    @for $i from 0 to 1000 {
        .p-sm-#{$i}px {
            padding: #{$i}px !important;
        }

        .pt-sm-#{$i}px {
            padding-top: #{$i}px !important;
        }

        .pb-sm-#{$i}px {
            padding-bottom: #{$i}px !important;
        }

        .ps-sm-#{$i}px {
            padding-left: #{$i}px !important;
        }

        .pe-sm-#{$i}px {
            padding-right: #{$i}px !important;
        }

        .px-sm-#{$i}px {
            padding-left: #{$i}px !important;
            padding-right: #{$i}px !important;
        }

        .py-sm-#{$i}px {
            padding-top: #{$i}px !important;
            padding-bottom: #{$i}px !important;
        }
    }
}

@include media-breakpoint-up(md) {
    @for $i from 0 to 1000 {
        .p-md-#{$i}px {
            padding: #{$i}px !important;
        }

        .pt-md-#{$i}px {
            padding-top: #{$i}px !important;
        }

        .pb-md-#{$i}px {
            padding-bottom: #{$i}px !important;
        }

        .ps-md-#{$i}px {
            padding-left: #{$i}px !important;
        }

        .pe-md-#{$i}px {
            padding-right: #{$i}px !important;
        }

        .px-md-#{$i}px {
            padding-left: #{$i}px !important;
            padding-right: #{$i}px !important;
        }

        .py-md-#{$i}px {
            padding-top: #{$i}px !important;
            padding-bottom: #{$i}px !important;
        }
    }
}

@include media-breakpoint-up(lg) {
    @for $i from 0 to 1000 {
        .p-lg-#{$i}px {
            padding: #{$i}px !important;
        }

        .pt-lg-#{$i}px {
            padding-top: #{$i}px !important;
        }

        .pb-lg-#{$i}px {
            padding-bottom: #{$i}px !important;
        }

        .ps-lg-#{$i}px {
            padding-left: #{$i}px !important;
        }

        .pe-lg-#{$i}px {
            padding-right: #{$i}px !important;
        }

        .px-lg-#{$i}px {
            padding-left: #{$i}px !important;
            padding-right: #{$i}px !important;
        }

        .py-lg-#{$i}px {
            padding-top: #{$i}px !important;
            padding-bottom: #{$i}px !important;
        }
    }
}

@include media-breakpoint-up(xl) {
    @for $i from 0 to 1000 {
        .p-xl-#{$i}px {
            padding: #{$i}px !important;
        }

        .pt-xl-#{$i}px {
            padding-top: #{$i}px !important;
        }

        .pb-xl-#{$i}px {
            padding-bottom: #{$i}px !important;
        }

        .ps-xl-#{$i}px {
            padding-left: #{$i}px !important;
        }

        .pe-xl-#{$i}px {
            padding-right: #{$i}px !important;
        }

        .px-xl-#{$i}px {
            padding-left: #{$i}px !important;
            padding-right: #{$i}px !important;
        }

        .py-xl-#{$i}px {
            padding-top: #{$i}px !important;
            padding-bottom: #{$i}px !important;
        }
    }
}

@include media-breakpoint-up(xxl) {
    @for $i from 0 to 1000 {
        .p-xxl-#{$i}px {
            padding: #{$i}px !important;
        }

        .pt-xxl-#{$i}px {
            padding-top: #{$i}px !important;
        }

        .pb-xxl-#{$i}px {
            padding-bottom: #{$i}px !important;
        }

        .ps-xxl-#{$i}px {
            padding-left: #{$i}px !important;
        }

        .pe-xxl-#{$i}px {
            padding-right: #{$i}px !important;
        }

        .px-xxl-#{$i}px {
            padding-left: #{$i}px !important;
            padding-right: #{$i}px !important;
        }

        .py-xxl-#{$i}px {
            padding-top: #{$i}px !important;
            padding-bottom: #{$i}px !important;
        }
    }
}