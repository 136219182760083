.mt-report-scroll-container {
    padding: 0px 22px;
    overflow: auto;
}

@include media-breakpoint-up(sm) {}

@include media-breakpoint-up(md) {}

@include media-breakpoint-up(lg) {
    .mt-report-scroll-container {
        height: calc(100vh - 598px);
    }
}

@include media-breakpoint-up(xl) {
    .mt-report-scroll-container {
        height: calc(100vh - 530px);
    }
}

@include media-breakpoint-up(xxl) {}