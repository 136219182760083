@each $key,
$value in $colors {
    .border-#{$key} {
        border-color: map-get($value, 'hex') !important;
    }

    .border-#{map-get($value, 'str')} {
        border-color: map-get($value, 'hex') !important;
    }
}

// border radius
@for $br from 0 to 100 {
    .br-#{$br}px {
        border-radius: #{$br}px;
    }

    .br-ts-#{$br}px {
        border-radius: #{$br}px 0px 0px 0px;
    }

    .br-te-#{$br}px {
        border-radius: 0px #{$br}px 0px 0px;
    }

    .br-bs-#{$br}px {
        border-radius: 0px 0px 0px #{$br}px;
    }

    .br-be-#{$br}px {
        border-radius: 0px 0px #{$br}px 0px;
    }

    .br-t-#{$br}px {
        border-radius: #{$br}px #{$br}px 0px 0px;
    }

    .br-s-#{$br}px {
        border-radius: #{$br}px 0px 0px #{$br}px;
    }

    .br-e-#{$br}px {
        border-radius: 0px #{$br}px #{$br}px 0px;
    }

    .br-b-#{$br}px {
        border-radius: 0px 0px #{$br}px #{$br}px;
    }
}