.mt-spinner {
    margin: 0 auto;

    & .mt-spinner-circle {
        width: 20px;
        height: 20px;
        border-radius: 50%;
        border: 2px solid $prussian-blue-2;
        border-top-color: $white;

        animation-name: rotate;
        animation-timing-function: linear;
        animation-duration: 0.5s;
        animation-iteration-count: infinite;
        animation-fill-mode: forwards;

        @keyframes rotate {
            from {
                transform: rotate(0deg);
            }

            to {
                transform: rotate(360deg);
            }
        }
    }
}