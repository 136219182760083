@import '../../node_modules/bootstrap/scss/functions';
@import '../../node_modules/bootstrap/scss/variables';
@import '../../node_modules/bootstrap/scss/mixins';
@import '../../node_modules/bootstrap/dist/css/bootstrap.css';

@import 'variables/index.scss';
@import 'themes/index.scss';
@import 'typography/index.scss';
@import 'gutters/index.scss';
@import 'components/index.scss';
@import 'pages/index.scss';
@import 'misc.scss';
@import 'animation.scss';

body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}