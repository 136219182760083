.mt-container {
    padding: 24px 0px;
    box-shadow: 0px 2px 48px $prussian-blue-2-fade;
    background: $white;
    border-radius: 24px;
    margin-bottom: 20px;
}

@include media-breakpoint-up(sm) {}

@include media-breakpoint-up(md) {}

@include media-breakpoint-up(lg) {
    .mt-container {
        margin-bottom: 0px;
    }
}

@include media-breakpoint-up(xl) {}

@include media-breakpoint-up(xxl) {}