@for $w from 0 to 1000 {
    .w-#{$w} {
        width: #{$w}#{"%"} !important;
    }

    .w-#{$w}px {
        width: #{$w}#{"px"} !important;
    }

    .max-w-#{$w}px {
        max-width: #{$w}#{"px"} !important;
    }

    .min-w-#{$w}px {
        min-width: #{$w}#{"px"} !important;
    }
}

@include media-breakpoint-up(sm) {
    @for $w from 0 to 1000 {
        .w-sm-#{$w} {
            width: #{$w}#{"%"} !important;
        }

        .w-sm-#{$w}px {
            width: #{$w}#{"px"} !important;
        }

        .max-w-sm-#{$w}px {
            max-width: #{$w}#{"px"} !important;
        }

        .min-w-sm-#{$w}px {
            min-width: #{$w}#{"px"} !important;
        }
    }
}

@include media-breakpoint-up(md) {
    @for $w from 0 to 1000 {
        .w-md-#{$w} {
            width: #{$w}#{"%"} !important;
        }

        .w-md-#{$w}px {
            width: #{$w}#{"px"} !important;
        }

        .max-w-md-#{$w}px {
            max-width: #{$w}#{"px"} !important;
        }

        .min-w-md-#{$w}px {
            min-width: #{$w}#{"px"} !important;
        }
    }
}

@include media-breakpoint-up(lg) {
    @for $w from 0 to 1000 {
        .w-lg-#{$w} {
            width: #{$w}#{"%"} !important;
        }

        .w-lg-#{$w}px {
            width: #{$w}#{"px"} !important;
        }

        .max-w-lg-#{$w}px {
            max-width: #{$w}#{"px"} !important;
        }

        .min-w-lg-#{$w}px {
            min-width: #{$w}#{"px"} !important;
        }
    }
}

@include media-breakpoint-up(xl) {
    @for $w from 0 to 1000 {
        .w-xl-#{$w} {
            width: #{$w}#{"%"} !important;
        }

        .w-xl-#{$w}px {
            width: #{$w}#{"px"} !important;
        }

        .max-w-xl-#{$w}px {
            max-width: #{$w}#{"px"} !important;
        }

        .min-w-xl-#{$w}px {
            min-width: #{$w}#{"px"} !important;
        }
    }
}

@include media-breakpoint-up(xxl) {
    @for $w from 0 to 1000 {
        .w-xxl-#{$w} {
            width: #{$w}#{"%"} !important;
        }

        .w-xxl-#{$w}px {
            width: #{$w}#{"px"} !important;
        }

        .max-w-xxl-#{$w}px {
            max-width: #{$w}#{"px"} !important;
        }

        .min-w-xxl-#{$w}px {
            min-width: #{$w}#{"px"} !important;
        }
    }
}