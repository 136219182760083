.mt-settings-modal {
    width: 170px;
    position: absolute;
    top: calc(100% + 10px);
    left: 50%;
    border-radius: 10px;
    transform: translateX(-50%);
    box-shadow: 0px 3px 6px $black-fade;
    background: $white;
    overflow: hidden;

    #{&}-item {
        font-size: 14px;
        padding: 8px;
        text-decoration: none;
        color: $prussian-blue-2;
        border-bottom: 1px solid $black-fade;

        &:hover {
            background-color: $texas-rose;
        }

        &:last-child {
            border-bottom: none;
        }
    }
}