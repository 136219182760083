.mt-analytics-tab-active {
    position: relative;

    &::after {
        position: absolute;
        content: '';
        display: block;
        width: 115%;
        height: 3px;
        background-color: $texas-rose;
        border-radius: 3px;
        top: 110%;
        left: 50%;
        transform: translateX(-50%);
    }
}

.mt-analytics-scroll-container {
    padding: 0px 22px;
    overflow: auto;
}

@include media-breakpoint-up(sm) {}

@include media-breakpoint-up(md) {}

@include media-breakpoint-up(lg) {
    .mt-analytics-scroll-container {
        padding: 0px 22px;
        height: calc(100vh - 315px);
    }
}

@include media-breakpoint-up(xl) {}

@include media-breakpoint-up(xxl) {}