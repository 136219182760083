.mt-card {
    background: $white;
    box-shadow: 0px 2px 6px $black-fade;
    border-radius: 8px;
    padding: 12px;
    cursor: pointer;

    &:hover {
        background: $gallery;
    }
}