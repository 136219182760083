.mt-radio {
    display: flex;
    align-items: center;
    cursor: pointer;

    .mt-radio-button {
        width: 18px;
        height: 18px;
        border-radius: 50%;
        border: 2px solid $prussian-blue-2;
        display: flex;
        align-items: center;
        justify-content: center;

        & .mt-checkmark {
            background: $prussian-blue-2;
            width: calc(100% - 4px);
            height: calc(100% - 4px);
            border-radius: 50%;
            display: none;
        }

        & input {
            display: none;

            &:checked {
                &+.mt-checkmark {
                    display: inline-block;
                }
            }
        }
    }
}