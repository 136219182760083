.mt-export-button {
    display: flex;
    align-items: center;
    justify-content: center;
    width: auto;
    height: 48px;
    border: 2px solid $regal-blue;
    outline: none;
    box-shadow: none;
    background-color: $texas-rose;
    color: $prussian-blue-2;
    border-radius: 24px;
    font-size: 14px;
    font-weight: 400;
    text-decoration: none;
    cursor: pointer;
    padding: 0px 48px;
    background-color: $white;
}