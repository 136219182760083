.mt-input {
    border-radius: 24px;
    height: 48px;
    width: 100%;
    border: 2px solid $texas-rose-fade;
    padding: 0px 25px;
    background-color: $white;
    color: $prussian-blue-2;
    font-size: 14px;
    font-weight: 400;
    box-shadow: none;
    outline: none;
    transition: border 0.2s linear;

    &:focus {
        border: 2px solid $texas-rose;
        transition: border 0.2s linear;
    }

    &::placeholder {
        color: $silver-chalice;
        font-size: 14px;
        font-weight: 400;
        transition: border 0.2s linear;
    }
}