.link {
    color: $prussian-blue-2;
    font-size: 14px;
    font-weight: bold;
    box-shadow: none;
    outline: none;
    border: none;

    &:hover {
        color: $prussian-blue-2;
    }

    &:focus {
        box-shadow: none;
        outline: none;
        border: none;
    }
}

.cursor-pointer {
    cursor: pointer;
}

::-webkit-scrollbar {
    width: 7px;
    height: 7px;
}

::-webkit-scrollbar-track {
    background: transparent;
}

::-webkit-scrollbar-thumb {
    border-radius: 7px;
    background: $alto;
}

::-webkit-scrollbar-thumb:hover {
    background: $prussian-blue-2;
}

@for $i from 0 to 10000 {
    .z-index-#{$i} {
        z-index: #{$i};
    }
}

input:focus {
    box-shadow: none;
    outline: none;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

input[type=number] {
    -moz-appearance: textfield;
}