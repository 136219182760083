.mt-modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    z-index: 100;
    overflow: hidden;

    & .mt-modal-wrapper {
        width: 100vw;
        height: 100vh;
        background-color: $prussian-blue-2-fade-1;
        overflow: hidden;
        display: flex;
        align-items: center;
        justify-content: center;

        & .mt-modal-content {
            max-height: calc(100vh - 100px);
            padding: 24px 0px;
            box-shadow: 0px 2px 48px $prussian-blue-2-fade;
            background: $white;
            border-radius: 24px;
            overflow: hidden;
        }
    }
}

.mt-trip-modal-scroll-container {
    padding: 0px 22px;
    max-width: 660px;
    width: calc(100vw - 20px);
    height: calc(100vh - 398px);
    overflow: auto;
}

.mt-account-settings-modal-scroll-container {
    padding: 0px 22px;
    max-width: 660px;
    width: calc(100vw - 20px);
    height: auto;
}

.mt-team-modal-scroll-container {
    padding: 0px 22px;
    max-width: 660px;
    width: calc(100vw - 20px);
    height: auto;
}

.mt-invite-team-member-scroll-container {
    padding: 0px 22px;
    max-width: 660px;
    width: calc(100vw - 20px);
    height: auto;
}

.mt-team-member-scroll-container {
    padding: 0px 22px;
    max-width: 1200px;
    width: calc(100vw - 20px);
    height: calc(100vh - 500px);
    overflow: auto;
}

.mt-team-member-container {
    padding: 0px 22px;
    width: calc(100vw - 20px);
    overflow: hidden;
    max-width: 1200px;
    height: auto;
}

@include media-breakpoint-up(sm) {
    .mt-trip-modal-scroll-container {
        height: calc(100vh - 330px);
        overflow: auto;
    }

    .mt-account-settings-modal-scroll-container {
        height: auto;
    }

    .mt-team-member-scroll-container {
        height: calc(100vh - 400px);
    }

    .mt-invite-team-member-scroll-container {
        height: auto;
    }

    .mt-team-modal-scroll-container {
        height: auto;
    }
}

@include media-breakpoint-up(md) {}

@include media-breakpoint-up(lg) {}

@include media-breakpoint-up(xl) {}

@include media-breakpoint-up(xxl) {}