.mt-home-container {
    padding: 10px 15px;
}

@include media-breakpoint-up(sm) {
    .mt-home-container {
        padding: 10px 20px;
    }
}

@include media-breakpoint-up(md) {
    .mt-home-container {
        padding: 10px 30px;
    }
}

@include media-breakpoint-up(lg) {
    .mt-home-container {
        padding: 10px 40px;
    }
}

@include media-breakpoint-up(xl) {
    .mt-home-container {
        padding: 10px 48px;
    }
}

@include media-breakpoint-up(xxl) {}