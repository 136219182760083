.mt-button {
    width: 100%;
    height: 48px;
    border: 2px solid $texas-rose;
    outline: none;
    box-shadow: none;
    background-color: $texas-rose;
    color: $prussian-blue-2;
    text-transform: uppercase;
    border-radius: 24px;
    font-size: 12px;
    font-weight: bold;
    text-decoration: none;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0px 30px;

    &:hover {
        border: 2px solid $texas-rose;
        outline: none;
        box-shadow: none;
        color: $prussian-blue-2;
        text-decoration: none;
        cursor: pointer;
    }

    &.mt-button-bordered {
        background-color: $white;
        border: 2px solid $texas-rose-fade;

        &:hover {
            border: 2px solid $texas-rose;
            background-color: $texas-rose;
        }
    }
}